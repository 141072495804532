import { Injectable } from '@angular/core';
import { IDeposit, DEPOSIT_PROVIDER_CODE, CURRENCY, ENUM_DEPOSITS_PROVIDERS } from '@libs/interfaces';
import { map, Observable, tap } from 'rxjs';
import { MapResponseData } from '@libs/rxjs/operators.rxjs';
import { ApiService } from '../api.service';
import { IToast, MODAL_TYPE } from '@libs/store/toast/model';
import { IModal } from '@libs/store/modal/model';
import { WalletService } from './wallet.service';
import { head } from 'lodash';
import { StoreToastModalsService } from '../store/store-toast-modals.service';
import { StoreWalletService } from '../store/store-wallet.service';
import { UserStoreService } from '../store/user-store.service';

@Injectable({
  providedIn: 'root',
})
export class DepositsFiatService {
  constructor(
    public api: ApiService,
    public storeToastModalsService: StoreToastModalsService,
    private walletService: WalletService,
    private storeWalletService: StoreWalletService,
    private userStoreService: UserStoreService,
  ) {}
  pixQrcode(deposit: IDeposit): Observable<IDeposit> {
    return this.api.post('deposits/pix/qrcode', deposit).pipe(
      MapResponseData,
      tap(() => this.storeWalletService.loadPendingDeposits()),
    );
  }
  boletoGenerate(deposit: IDeposit): Observable<IDeposit> {
    return this.api.post('deposits/boleto/generate', deposit).pipe(
      MapResponseData,
      tap(() => this.storeWalletService.loadPendingDeposits()),
    );
  }
  cancelBoleto(deposit: IDeposit): Observable<IDeposit> {
    return this.api.delete(`deposits/boleto/cancel/${deposit._id}`).pipe(
      MapResponseData,
      tap(() => this.storeWalletService.loadPendingDeposits()),
    );
  }
  pendingDeposits(): Observable<IDeposit[]> {
    return this.api.get('deposits/pending').pipe(MapResponseData);
  }
  pendingBoleto(): Observable<IDeposit> {
    return this.selectPendingDeposits().pipe(
      map((deposits: IDeposit[]) =>
        deposits.filter((deposit: IDeposit) => deposit.provider.code === DEPOSIT_PROVIDER_CODE.BOLETO),
      ),
      map((deposits: IDeposit[]) => head(deposits)),
    );
  }
  cancelDepositByUser(deposit: IDeposit): Observable<boolean> {
    return this.api.get(`deposits/cancelDepositByUser/${deposit._id}`).pipe(
      MapResponseData,
      tap(() => this.storeWalletService.loadPendingDeposits()),
    );
  }
  fetchDepositById({ deposit_id }): Observable<IDeposit> {
    return this.api.get(`deposits/fetchDepositById/${deposit_id}`).pipe(MapResponseData);
  }
  fetchUserFiatPendingRequest(): Observable<IDeposit> {
    return this.api
      .get(`deposits/fetchUserFiatPendingRequest/${this.walletService.wallet().code}`)
      .pipe(MapResponseData);
  }
  selectPendingDeposits(): Observable<IDeposit[]> {
    return this.storeWalletService.selectPendingDeposits();
  }

  copyQrcodeToast() {
    const toast: IToast = {
      id: Date.now(),
      info: 'ACCOUNT.QCCS',
      type: MODAL_TYPE.SUCCESS,
      exp: 2000,
    };
    this.storeToastModalsService.addToast(toast);
  }
  cancelPixModal(deposit: IDeposit) {
    const modal: IModal = {
      subject: 'WALLET.DEPOSITO PIX',
      info: 'ACCOUNT.VQCOD',
      onAccept: this.cancelDepositByUser(deposit),
    };
    this.storeToastModalsService.addModal(modal);
  }
  cpfError() {
    const toast: IToast = {
      id: Date.now(),
      exp: 3000,
      info: 'ACCOUNT.OCPFERROR',
      type: MODAL_TYPE.ERROR,
    };
    this.storeToastModalsService.addToast(toast);
  }
  minAmountWarn() {
    const toast: IToast = {
      id: Date.now(),
      info: 'ACCOUNT.MINAMOUNT',
      type: MODAL_TYPE.WARNING,
    };
    this.storeToastModalsService.addToast(toast);
  }
  emptyPixDeposit(amount: number): IDeposit {
    return {
      amount: amount,
      currency: CURRENCY[this.walletService.wallet().code],
      user_id: this.userStoreService.user()._id,
      timestamp: Date.now(),
      userData: {
        email: this.userStoreService.user().data.email,
        cpf: this.userStoreService.user().data.national_id,
        name: this.userStoreService.user().data.name,
        surname: this.userStoreService.user().data.surname,
      },
      provider: {
        by: ENUM_DEPOSITS_PROVIDERS.GN,
        code: DEPOSIT_PROVIDER_CODE.PIX,
        qrcode: '',
      },
    };
  }
  emptyBoletoDeposit(amount: number): IDeposit {
    return {
      amount: amount,
      currency: CURRENCY[this.walletService.wallet().code],
      user_id: this.userStoreService.user()._id,
      timestamp: Date.now(),
      userData: {
        email: this.userStoreService.user().data.email,
        cpf: this.userStoreService.user().data.national_id,
        name: this.userStoreService.user().data.name,
        surname: this.userStoreService.user().data.surname,
      },
      provider: {
        by: ENUM_DEPOSITS_PROVIDERS.GN,
        code: DEPOSIT_PROVIDER_CODE.BOLETO,
        url: 'assets/payments/BL.png',
      },
    };
  }
}
